export const tableStateList = [
  { text: '启用', type: 'success', value: 1 },
  { text: '停用', type: 'danger', value: 0 }
]

export const tableDataStateList = [
  { text: '封禁', type: 'danger', value: 1 },
  { text: '正常', type: 'success', value: 2 },
  { text: '已删除', type: 'info', value: 0 }
]

export const stateList = [
  { text: '启用', value: 1 },
  { text: '停用', value: 0 }
]

export const genderList = [
  { text: '未知', type: 'info', value: 0 },
  { text: '男', type: 'success', value: 1 },
  { text: '女', type: 'danger', value: 2 }
]

export const bannerTypeList = [
  { text: '图片', type: 'info', value: 0 },
  { text: 'AD', type: 'warning', value: 1 },
  { text: '分类', type: 'success', value: 2 },
  { text: '标签', type: 'primary', value: 3 },
  { text: '网页', type: 'danger', value: 4 }
]

export const recommendList = [
  { text: '是', type: 'success', value: true },
  { text: '否', type: 'info', value: false }
]

export const intRecommendList = [
  { text: '是', type: 'success', value: 1 },
  { text: '否', type: 'info', value: 0 }
]

export const appImageInfoTypes = [
  { text: '最近一天', value: 1 },
  { text: '最近一周', value: 7 },
  { text: '最近一月', value: 30 }
]

export const operatingTypes = [
  { text: '查询', value: 0, type: 'info' },
  { text: '新增', value: 1, type: 'success' },
  { text: '修改', value: 2, type: 'primary' },
  { text: '删除', value: 3, type: 'danger' },
  { text: '上传', value: 4, type: 'warning' }
]

export const responseTypes = [
  { text: '成功', value: true, type: 'success' },
  { text: '失败', value: false, type: 'danger' }
]