<template>
  <el-container class="main-container">
    <el-row :gutter="10">
      <el-col :span="4">
        <el-button @click="add">新增</el-button>
      </el-col>
    </el-row>
    <el-card style="margin-top: 20px;">
      <el-row :gutter="10">
        <el-col :span="4" :offset="0">
          <el-input
            v-model="queryInfo.query"
            placeholder="请输入内容"
            clearable
            @clear="query"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="query"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <ele-select v-model="queryInfo.state"
            placeholder="状态筛选"
            :options="dataStateList"
            @change="query"></ele-select>
        </el-col>
      </el-row>
      <ele-table :columns-desc="columns"
        :is-show-index="true"
        :request-fn="getSystemUsers"
        :right-buttons="rightButtons"
        :is-show-top-delete="false"
        :is-show-right-delete="false"
        ref="table"></ele-table>
    </el-card>
    <ele-form-dialog 
      v-model="formData"
      :form-desc="formDesc"
      :title="isEdit ? '编辑用户' : '添加用户'"
      :request-fn="handleSubmit"
      :visible.sync="dialogVisible"
      @closed="handleClosed"></ele-form-dialog>
  </el-container>
</template>

<script>
import{ tableDataStateList } from '../../assets/js/constant'
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        state: ''
      },
      dataStateList: tableDataStateList,
      columns: {
        userName: {
          text: '用户名'
        },
        nickName: {
          text: '昵称'
        },
        state: {
          text: '状态',
          type: 'status',
          options: tableDataStateList
        }
      },
      rightButtons: [
        {
          text: '编辑',
          attrs: {
            type: 'primary'
          },
          click: (id, index, data) => {
            this.edit(data)
          }
        }
      ],
      isEdit: false,
      dialogVisible: false,
      formData: {},
      formDesc: {
        userName: {
          label: '用户名',
          type: 'input'
        },
        nickName: {
          label: '昵称',
          type: 'input'
        },
        state: {
          label: '状态',
          type: 'radio',
          options: tableDataStateList,
          default: true
        }
      }
    }
  },
  created() {},
  methods: {
    query() {
      this.$refs.table.getData()
    },
    async getSystemUsers(params) {
      const { data: res } = await this.$api.getSystemUsers(Object.assign(params, this.queryInfo))
      if (res.code === 1) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      return res.data
    },
    add() {
      this.isEdit = false
      this.dialogVisible = true
    },
    async edit(data) {
      this.formData = data
      this.isEdit = true
      this.dialogVisible = true
    },
    async handleSubmit(data) {
      const { data: res } = await this.$api.editSystemUser(data)
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.dialogVisible = false
      this.query()
      this.$notify.success({
        title: '成功',
        message: res.message
      })
    },
    handleClosed() {
      this.formData = {state: true}
    }
  }
}
</script>

<style lang="less" scoped></style>
